import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../components/seo';
import { HOME } from '../routes';
import { graphql, Link } from 'gatsby';
import logoSrc from '../images/logo-pod-rimskym-vrchem.png';
import theme from '../theme';

const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    width: 540px;
  }

  @media (min-width: 768px) {
    /* width: 720px; */
    width: 620px;
  }

  @media (min-width: 992px) {
    /* width: 960px; */
    width: 860px;
  }

  @media (min-width: 1200px) {
    /* width: 1140px; */
    width: 1040px;
  }

  @media (min-width: 1400px) {
    width: 1290px;
  }

  @media (min-width: 1600px) {
    width: 1520px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  /* background-color: #625f59; */
  background-color: #fff;

  img {
    width: 100%;
    height: auto;
    max-width: 218px;
    margin-bottom: 2rem;
  }

  /* @media (min-width: 768px) {
    img {
      max-width: 320px;
    }
  } */
`;

const StyledLink = styled(Link)`
  font-size: 1rem;
  /* line-height: 1.875rem; */
  color: #fff;
  /* background-color: ${({theme}) => theme.colors.secondary}; */
  background-color: #303030;
  border: 0;
  width: 100%;
  font-weight: 400;
  padding: 0.75rem 2.25rem;
  transition: all .3s ease;
  /* border-radius: 8px; */
  /* border: 2px solid ${({theme}) => theme.colors.secondary}; */
  text-decoration: none;
    color: #fff;
    background-color: #303030;
    padding: 1rem 3rem;

    text-transform: uppercase;

  &:hover {
    /* text-decoration: none;
    color: ${({theme}) => theme.colors.white};
    background-color: transparent; */
    color: #fff;
    text-decoration: none;
    background-color: #ff940a;
  }
`;

const Title = styled.h1`
  font-size: 4rem;
  line-height: 4.5rem;
  /* font-family: 'Roboto', Georgia, sans-serif; */
  /* color: ${({theme}) => theme.colors.primary_text}; */
  color: #303030;
  font-weight: 600;
  text-align: left;
  margin-bottom: 4.375rem;
  text-transform: uppercase;
`;

const Container = styled.div`
  padding: 7rem 0;
  text-align: center;

  ${Title} {
    font-size: 5rem;
    line-height: 6.25rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
    color: #303030;
    text-align: center;
  }

  p {
    font-size: 1.125rem;
    line-height: 3.75rem;
    margin-bottom: 2.5rem;
    color: #303030;
  }
`;

function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Seo title={t('seo.not_found.title')} />
        <Container>
          <ContentWrapper>
            <img src={logoSrc} alt={t('logo_alt')} />
            <Title>404</Title>
            <p>{t('not_found.message')}</p>
            <StyledLink to={HOME}>{t('not_found.btn_label')}</StyledLink>
          </ContentWrapper>
        </Container>
      </Wrapper>
    </ThemeProvider>
  )
}

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

